export const columns = [
{
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '性别',
    dataIndex: 'sex',
    key: 'sex',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '邮箱',
    dataIndex: 'email',
    key: 'email',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '生日',
    dataIndex: 'birthday',
    key: 'birthday',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '入职日期',
    dataIndex: 'joinDate',
    key: 'joinDate',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '离职日期',
    dataIndex: 'leaveDate',
    key: 'leaveDate',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '转正日期',
    dataIndex: 'formalDate',
    key: 'formalDate',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '在职状态',
    dataIndex: 'workStatus',
    key: 'workStatus',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '学历',
    dataIndex: 'education',
    key: 'education',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '是否删除',
    dataIndex: 'flagDel',
    key: 'flagDel',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '版本号',
    dataIndex: 'version',
    key: 'version',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '部门id',
    dataIndex: 'deptId',
    key: 'deptId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '岗位id',
    dataIndex: 'stationId',
    key: 'stationId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '职位id',
    dataIndex: 'positionId',
    key: 'positionId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '经销商id',
    dataIndex: 'dealerId',
    key: 'dealerId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
